<template>
  <div class="nsi-item">

    <div
      v-if="!pageLoad"
      class="page-loader"
    >
      <div
        class="spinner-border text-primary"
        role="status"
      >
        <span class="sr-only">Loading...</span>
      </div>
    </div>

    <div class="page-title d-flex py-2 justify-content-between">

      <h1 class="h4 mb-0">Запрос № {{ currentNsi.number }} от {{ currentNsi.date | parseDate }}</h1>

      <div class="d-flex align-items-center">
        <BButton
          class="ml-3"
          variant="warning"
          @click="onExport( 'nsi-list', id )"
        >Экспорт
        </BButton>
        <BButton
          v-if="currentNsi.organization_status === 'Черновик'"
          class="ml-3"
          variant="outline-primary"
          :to="`${$route.fullPath}/edit`"
        >Редактировать
        </BButton>
      </div>

    </div>

    <div class="mb-4">

      <div class="d-flex table-bordered mb-1">
        <div
          class="px-2 py-1 d-flex align-items-center font-weight-bold border-right bg-light text-muted flex-shrink-0"
          style="width: 250px;"
        >Название компании
        </div>
        <div class="px-2 py-1 flex-grow-1">{{ currentNsi.contr_agent_name || '-' }}</div>
      </div>

      <div class="d-flex table-bordered mb-1">
        <div
          class="px-2 py-1 d-flex align-items-center font-weight-bold border-right bg-light text-muted flex-shrink-0"
          style="width: 250px;"
        >Тип
        </div>
        <div class="px-2 py-1 flex-grow-1">{{ ( { new: 'Новый', change: 'Изменить' } )[ currentNsi.type ] || '-' }}</div>
      </div>

      <b-row>

        <b-col cols="6">
          <div class="d-flex table-bordered mb-1">
            <div
              class="px-2 py-1 d-flex align-items-center font-weight-bold border-right bg-light text-muted flex-shrink-0"
              style="width: 250px;"
            >№ запроса
            </div>
            <div class="px-2 py-1 flex-grow-1">{{ currentNsi.number }}</div>
          </div>
        </b-col>

        <b-col cols="6">
          <div class="d-flex table-bordered mb-1">
            <div
              class="px-2 py-1 d-flex align-items-center font-weight-bold border-right bg-light text-muted flex-shrink-0"
              style="width: 250px;"
            >Дата запроса
            </div>
            <div class="px-2 py-1 flex-grow-1">{{ currentNsi.date | parseDate }}</div>
          </div>
        </b-col>

      </b-row>

      <b-row>

        <b-col cols="6">
          <div class="d-flex table-bordered mb-1">
            <div
              class="px-2 py-1 d-flex align-items-center font-weight-bold border-right bg-light text-muted flex-shrink-0"
              style="width: 250px;"
            >№ договора подряда/поставки
            </div>

            <div class="px-2 py-1 flex-grow-1"
              v-if="isProvider"
            >
              <div v-if="currentNsi.provider_contract">
                {{ currentNsi.provider_contract.number }}
              </div>
            </div>

            <div class="px-2 py-1 flex-grow-1"
              v-if="isContractor"
            >
              <div v-if="currentNsi.work_agreement">
                {{ currentNsi.work_agreement.number }}
              </div>
            </div>
          </div>
        </b-col>

        <b-col cols="6">
          <div class="d-flex table-bordered mb-1">
            <div
              class="px-2 py-1 d-flex align-items-center font-weight-bold border-right bg-light text-muted flex-shrink-0"
              style="width: 250px;"
            >Дата договора подряда/поставки
            </div>
            <div class="px-2 py-1 flex-grow-1">
              <div v-if="currentNsi.provider_contract">
                {{ currentNsi.provider_contract.date | parseDate }}
              </div>
            </div>
          </div>
        </b-col>

      </b-row>

      <div class="d-flex table-bordered mb-1">
        <div
          class="px-2 py-1 d-flex align-items-center font-weight-bold border-right bg-light text-muted flex-shrink-0"
          style="width: 250px;"
        >Филиал
        </div>
        <div class="px-2 py-1 flex-grow-1">{{ currentNsi.organization.name }}</div>
      </div>

      <div class="h5 mt-4">Менеджер</div>

      <div class="mb-4">
        <div class="d-flex table-bordered mb-1">
          <div
            class="px-2 py-1 d-flex align-items-center font-weight-bold border-right bg-light text-muted flex-shrink-0"
            style="width: 250px"
          >
            ФИО менеджера
          </div>
          <div class="px-2 py-1 flex-grow-1">
            {{ ( currentNsi.work_agreement && currentNsi.work_agreement.manager_full_name ) || '-' }}
          </div>
        </div>

        <div class="d-flex table-bordered mb-1">
          <div
            class="px-2 py-1 d-flex align-items-center font-weight-bold border-right bg-light text-muted flex-shrink-0"
            style="width: 250px"
          >
            Телефон менеджера
          </div>
          <div class="px-2 py-1 flex-grow-1">
            {{ ( currentNsi.work_agreement && currentNsi.work_agreement.manager_phone ) || '-' }}
          </div>
        </div>

        <div class="d-flex table-bordered mb-1">
          <div
            class="px-2 py-1 d-flex align-items-center font-weight-bold border-right bg-light text-muted flex-shrink-0"
            style="width: 250px"
          >
            Адрес электронной почты менеджера
          </div>
          <div class="px-2 py-1 flex-grow-1">
            {{ ( currentNsi.work_agreement && currentNsi.work_agreement.manager_email ) || '-' }}
          </div>
        </div>
      </div>

      <div class="d-flex table-bordered mb-1">
        <div
          class="px-2 py-1 d-flex align-items-center font-weight-bold border-right bg-light text-muted flex-shrink-0"
          style="width: 250px;"
        >Статус
        </div>
        <div class="px-2 py-1 flex-grow-1">{{ currentNsi.organization_status }}</div>
      </div>

      <div class="d-flex table-bordered mb-1">
        <div
          class="px-2 py-1 d-flex align-items-center font-weight-bold border-right bg-light text-muted flex-shrink-0"
          style="width: 250px;"
        >Номенклатура
        </div>
        <div class="px-2 py-1 flex-grow-1">{{ currentNsi.nomenclature ? currentNsi.nomenclature.name : currentNsi.nomenclature_name }}</div>
      </div>

      <div class="d-flex table-bordered mb-1">
        <div
          class="px-2 py-1 d-flex align-items-center font-weight-bold border-right bg-light text-muted flex-shrink-0"
          style="width: 250px;"
        >Единицы измерения
        </div>
        <div class="px-2 py-1 flex-grow-1"> 
          <div v-if="currentNsi.nomenclature">
            {{ currentNsi.nomenclature.units && currentNsi.nomenclature.units.length ? currentNsi.nomenclature.units[0].name : '' }}
          </div>
          <div v-else>
            {{ currentNsi.nomenclature_unit }}
          </div>
        </div>
      </div>

      <div class="d-flex table-bordered mb-1">
        <div
          class="px-2 py-1 d-flex align-items-center font-weight-bold border-right bg-light text-muted flex-shrink-0"
          style="width: 250px;"
        >Характеристика / описание
        </div>
        <div class="px-2 py-1 flex-grow-1">{{ currentNsi.description }}</div>
      </div>

      <div class="d-flex table-bordered mb-1">
        <div
          class="px-2 py-1 d-flex align-items-center font-weight-bold border-right bg-light text-muted flex-shrink-0"
          style="width: 250px;"
        >Ответственный
        </div>
        <div class="px-2 py-1 flex-grow-1">{{ currentNsi.responsible_full_name }}</div>
      </div>

      <div class="d-flex table-bordered mb-1">
        <div
          class="px-2 py-1 d-flex align-items-center font-weight-bold border-right bg-light text-muted flex-shrink-0"
          style="width: 250px;"
        >Комментарий
        </div>
        <div class="px-2 py-1 flex-grow-1">{{ currentNsi.contr_agent_comment }}</div>
      </div>

      <div class="d-flex table-bordered mb-1">
        <div
          class="px-2 py-1 d-flex align-items-center font-weight-bold border-right bg-light text-muted flex-shrink-0"
          style="width: 250px;"
        >Комментарий заказчика
        </div>
        <div class="px-2 py-1 flex-grow-1">{{ currentNsi.organization_comment }}</div>
      </div>

      <div
        v-if="currentNsi.file_url"
        class="d-flex table-bordered mb-1"
      >
        <div
          class="px-2 py-1 d-flex align-items-center font-weight-bold border-right bg-light text-muted flex-shrink-0"
          style="width: 250px;"
        >Приложение</div>
        <div class="px-2 py-1 flex-grow-1">
          <DownloadLink
            :id="id"
            from="request-addition/nomenclature"
            :url="currentNsi.file_url"
          />
        </div>
      </div>

      <div class="h5 mt-4">Контактное лицо</div>

      <div class="mb-4">
        <div class="d-flex table-bordered mb-1">
          <div
            class="px-2 py-1 d-flex align-items-center font-weight-bold border-right bg-light text-muted flex-shrink-0"
            style="width: 250px"
          >
            ФИО контактного лица
          </div>
          <div class="px-2 py-1 flex-grow-1">
            {{ ( currentNsi.contact_person_full_name ) || '-' }}
          </div>
        </div>

        <div class="d-flex table-bordered mb-1">
          <div
            class="px-2 py-1 d-flex align-items-center font-weight-bold border-right bg-light text-muted flex-shrink-0"
            style="width: 250px"
          >
            Телефон контактного лица
          </div>
          <div class="px-2 py-1 flex-grow-1">
            {{ ( currentNsi.contact_person_phone ) || '-' }}
          </div>
        </div>

        <div class="d-flex table-bordered mb-1">
          <div
            class="px-2 py-1 d-flex align-items-center font-weight-bold border-right bg-light text-muted flex-shrink-0"
            style="width: 250px"
          >
            Адрес электронной почты контактного лица
          </div>
          <div class="px-2 py-1 flex-grow-1">
            {{ ( currentNsi.contact_person_email ) || '-' }}
          </div>
        </div>
      </div>

    </div>

  </div>
</template>

<script>
import {mapState, mapGetters} from 'vuex'
import DownloadLink from "@/components/UI/DownloadLink";

export default {
  name: "NsiItem",
  components: {
    DownloadLink,
  },
  computed: {
    id() {
      return this.$route.params.id;
    },
    ...mapState({
      currentNsi: (state) => state.nsi.currentNsi,
      user: (state) => state.account.user,
    }),
    breadcrumbs() {
      return [
        { title: "Главная", link: "/" },
        { title: "Перечень запросов на добавление НСИ", link: "/nsi-list" },
        {
          title: `Запрос № ${
            this.currentNsi.number
          } от ${this.$options.filters.parseDate(this.currentNsi.date)}`,
        },
      ];
    },
    ...mapGetters({
        isProvider: 'isProvider',
        isContractor: 'isContractor'
    }),
  },
  data: () => ({
    pageLoad: false,
  }),
  async mounted() {
    const id = this.$route.params.id;
    await this.$store.dispatch("getCurrentNsi", id).catch((err) => {
      if (err.response.status === 404) {
        this.$router.push("/nsi-list");
      }
    });
    this.$store.dispatch("checkNotification", {
      entity: "request_addition_nomenclature",
      id: id,
    });
    this.pageLoad = true;
  },
};
</script>

<style
    lang="sass"
    scoped
>
</style>
